import styled from "styled-components"
import { mediaQueries } from "./mediaQueries";

export const StyledH1 = styled.h1`
    color: white;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0;
    font-weight: 100;
`;

export const StyledP = styled.p`
    color: white;
    font-family: 'Bebas Neue', Helvetica;
    font-size: 40px;
    text-align: center;
    line-height: 1.2em;
    text-transform: uppercase;
    margin: 0.25em 0;
    color: ${props => props.color || 'white'};

    span.justified {
        display: block;
        text-align: justify;

        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 0px;
        }
    }

    span.post-justified {
        display: inline-block;
        margin-top: -1em;
    }

    ${mediaQueries("tablet")`
        font-size: 24px;
    `};
`