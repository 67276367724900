import React from "react";

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = React.useState({width: undefined, height: undefined});  
  React.useEffect(() => {
    if (typeof window !== `undefined`) {

      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    
      window.addEventListener("resize", handleResize);
      
      handleResize();
      
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [])

  return windowSize
}