import { createGlobalStyle } from 'styled-components';

import championBantamweightTff from '../fonts/Champion/champion-htf-bantamweight-webfont.ttf';
import championBantamweightWoff from '../fonts/Champion/champion-htf-bantamweight-webfont.woff';
import championFeatherweightTff from '../fonts/Champion/champion-htf-featherweight-webfont.ttf';
import championFeatherweightWoff from '../fonts/Champion/champion-htf-featherweight-webfont.woff';
import championHeavyweightTff from '../fonts/Champion/champion-htf-heavyweight-webfont.ttf';
import championHeavyweightWoff from '../fonts/Champion/champion-htf-heavyweight-webfont.woff';
import championLightweightTff from '../fonts/Champion/champion-htf-lightweight-webfont.ttf';
import championLightweightWoff from '../fonts/Champion/champion-htf-lightweight-webfont.woff';
import championMiddleweightTff from '../fonts/Champion/champion-htf-middleweight-webfont.ttf';
import championMiddleweightWoff from '../fonts/Champion/champion-htf-middleweight-webfont.woff';
import championWelterweightTff from '../fonts/Champion/champion-htf-welterweight-webfont.ttf';
import championWelterweightWoff from '../fonts/Champion/champion-htf-welterweight-webfont.woff';

import volcanoTff from '../fonts/Dos_de_tres/Volcano.ttf';
import volcanoWoff from '../fonts/Dos_de_tres/Volcano.woff';

import starsWoff from '../fonts/SeeingStars/seeis.woff'

export default createGlobalStyle`
    @font-face {
        font-family: 'Champion Featherweight';
        src: local('Champion Featherweight'), local('ChampionFeatherweight'),
        url(${championFeatherweightWoff}) format('woff'),
        url(${championFeatherweightTff}) format('tff');
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: 'Champion Lightweight';
        src: local('Champion Lightweight'), local('ChampionLightweight'),
        url(${championLightweightWoff}) format('woff'),
        url(${championLightweightTff}) format('tff');
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: 'Champion Middleweight';
        src: local('Champion Middleweight'), local('ChampionMiddleweight'),
        url(${championMiddleweightWoff}) format('woff'),
        url(${championMiddleweightTff}) format('tff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Champion Bantamweight';
        src: local('Champion Bantamweight'), local('ChampionBantamweight'),
        url(${championBantamweightWoff}) format('woff'),
        url(${championBantamweightTff}) format('tff');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Champion Welterweight';
        src: local('Champion Welterweight'), local('ChampionWelterweight'),
        url(${championWelterweightWoff}) format('woff'),
        url(${championWelterweightTff}) format('tff');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Champion Heavyweight';
        src: local('Champion Heavyweight'), local('ChampionHeavyweight'),
        url(${championHeavyweightWoff}) format('woff'),
        url(${championHeavyweightTff}) format('tff');
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
        font-family: 'Volcano';
        src: local('Volcano'), local('Volcano'),
        url(${volcanoWoff}) format('woff'),
        url(${volcanoTff}) format('tff');
        font-weight: 300;
        font-style: normal;
        font-display: block;
    }
    @font-face {
        font-family: 'Stars';
        src: local('Stars'), local('Stars'),
        url(${starsWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: block;
    }
`;