import React from "react"
import styled from "styled-components"

interface FullScreenContainerProps {
    backgroundImage: string,
    className?: string,
    id?: string,
}

interface FullScreenContainerComponentProps extends FullScreenContainerProps {
  children: any,
}

const StyledFullScreenContainer = styled.div`
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    background-size: cover;
    background-image: url("${(props:FullScreenContainerProps) => props.backgroundImage}");
    z-index: 0;
`

const FullScreenContainer = ({ children, className, backgroundImage, id }:FullScreenContainerComponentProps) => (
  <StyledFullScreenContainer id={id} className={className} backgroundImage={backgroundImage}>
    {children}
  </StyledFullScreenContainer>
)

export default FullScreenContainer
