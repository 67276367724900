import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import luchadorMp3 from "../audio/luchador.mp3";
import blog from "../images/header/blog.png";
import facebook from "../images/header/facebook.png";
import grit from "../images/header/grit.png";
import info from "../images/header/info.png";
import instagram from "../images/header/instagram.png";
import pause from "../images/header/pause-btn.png";
import play from "../images/header/play-btn.png";
import twitter from "../images/header/twitter.png";
import logoLuchadorLockup from '../images/header/luchador-logo-lockup.svg';
import '../styles/lib/hamburgers.min.css';
import { mediaQueries } from "../styles/mediaQueries";
import { StyledH1, StyledP } from "../styles/typography";
import { useWindowResize } from "../utilities/hooks";
import { scrollTo } from "../utilities/luchador-utils";
import FullScreenContainer from "./full-screen-container";
import LuchadorImage from "./luchador-image";
import Modal from "./modal";


const StyledHeader = styled.header`
  background: rgba(255,255,255,0.5);
  height: 100%;
  left: 30px;
  position: fixed;
  top: 0;
  width: 50px;
  z-index: 1;
  transition: transform 0.5s cubic-bezier(0,0,0,0.5);
  overflow: visible;

  .hamburger {
    position: absolute;
    top: 0;
    left: 100%;
    -webkit-appearance: div;
    outline: 0;

    &-inner {
      background-color: black;
      &:before,
      &:after {
        background-color: black;
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: black;
        &:before,
        &:after {
          background-color: black;
        }
      }
    }
  }

  ${mediaQueries("tablet")`
    left: initial;
    right: 100%;
  `};
`

const HeaderSections = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: auto;
  padding: 50px 0;
  width: 100%:

`

const Section = styled.div`
  font-family: 'Volcano';
  font-size: 36px;
  padding: 4px;
`

const Bottom = styled.div`
`

const Circle = styled.div`
  align-items: center;
  border-radius: 21px;
  cursor: pointer;
  display: flex;
  background-image: url(${grit});
  background-color: black;
  background-repeat: repeat;
  background-size: cover;
  height: 42px;
  justify-content: center;
  margin: 12px 0;
  width: 42px;

  &:hover {
    background-color: #cc092f;
  }

  &:first-child {
    background-position-x: 10px;
  }

  &:nth-child(2) {
    background-position-x: -20px;
  }

  &:nth-child(3) {
    background-position-x: 40px;
  }

  &:nth-child(4) {
    background-position-x: -50px;
  }

  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
    margin: 0;
  }
`

const SectionMask = styled.p`
  font-size: 48px;
  margin: 16px auto;
  cursor: pointer;
  line-height: 1em;
  text-align: center;

  &:hover,
  &.active {
    color: #cc092f;
  }
`

const MusicButton = styled.img`
  cursor: pointer;
  height: auto;
  margin: 0.5em auto 0;
  width: 25px;
`

const StyledModalSection = styled(FullScreenContainer)`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
    max-height: calc(100vh - 80px);
    overflow: hidden;
    max-width: calc(80vw - 80px);
    overflow: scroll;
    padding: 32px;


    ${mediaQueries("maxHeadline")`
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;
    `};

    ${mediaQueries("tablet")`
      max-width: calc(100vw - 80px);
    `};
`

const ModalSection = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  height: 100%;

  &.left {
    flex: 0 0 45%;
    padding-right: 0;
    
    ${mediaQueries("maxHeadline")`
        flex: 0 0 100%;
    `};
  }
  &.right {
    flex: 0 0 55%;
    padding-left: 0;
    
    ${mediaQueries("maxHeadline")`
        flex: 0 0 100%;
    `};
    
  }
`

const ModalHeader = styled.div`
  h1 {
    font-family: 'Champion Heavyweight';
    color: black;
    font-size: 43.2px;
    line-height: 1.74em;
    margin-top: 0;
    text-align: center;

    ${mediaQueries("xl")`
      font-size: 2.25vw;
    `};

    ${mediaQueries("maxHeadline")`
      font-size: 3.25vw;
    `};

    span {
      display: block;
      font-size: 2em;
    }
  }
`

const BoxesImage = styled.div`
  margin: 2em auto;
  transform: translateX(6.5%);
`

const BoxImages = styled.div`
  flex: 1;
  position: relative;
  transform: translate(3.5%);
  ${mediaQueries("maxHeadline")`
    display: none;
  `};
`

const BoxStackImage = styled.div`
  width: 75%;
  max-width: 800px;
  padding-bottom: 15%;
`

const BoxImage = styled.div`
  position: absolute;
  top: 52%;
  left: 29%;
  width: 85%;
`

const Logos = styled.div`
  display: flex;
  height: 144px;
  justify-content: space-between;
  width: 100%;

  & > * {
    margin: 0;
    height: 100%;
  }

  img {
    margin: 0;
    height: 100%;
  }
`

const MailLink = styled.a`
  text-decoration: none;
  &:hover {
    p {
      color: red;
    }
  }
`


const CircleButton = ({src}) => {
  return <Circle>
    <img src={src} />
  </Circle>
}

const sectionChars = [
  "L",
  "O",
  "X",
  "P",
  "B",
  "J",
]

const Header = () => {
  const { background, box, boxes, boxStack, buy }: HeaderQuery = useStaticQuery(
      graphql`
          query Header {
              background: file(
                  relativePath: { eq: "home/HOMEPAGE_BG_N0_CHAIRMAN_x2.jpg"}
              ) {
                  ...backgroundImage
              }
              box: file(
                  relativePath: { eq: "section-6/PAGE_6_VARIETY_CASE_RGB_x2.png"}
              ) {
                  childImageSharp {
                      fluid(maxWidth: 1620) {
                          src
                      }
                  }
              },
              boxes: file(
                  relativePath: { eq: "section-5/PAGE_5_4PACKS_x2.png"}
              ) {
                  childImageSharp {
                      fluid(maxWidth: 1661) {
                          src
                      }
                  }
              },
              boxStack: file(
                  relativePath: { eq: "section-6/PAGE_6_STACK_3CASES_RGB_x2.png"}
              ) {
                  childImageSharp {
                      fluid(maxWidth: 1603) {
                          src
                      }
                  }
              },
          }
      `
  );
  
  const audioRef = React.createRef<HTMLAudioElement>();
  const [playing, setPlaying] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState(undefined);
  const [mobileMenuActive, setMobileMenuActive] = React.useState(false);
  const windowSize = useWindowResize();
  const isSmall = windowSize.width <= 768;
  
  const toggleMusic = () => {
    if(playing){
      turnOffMusic()
    }else{
      turnOnMusic()
    }
  }

  const turnOffMusic = () => {
    setPlaying(false);
  }

  const turnOnMusic = () => {
    setPlaying(true);
  }

  React.useEffect(() => {
    var music = audioRef.current;
    music.volume = .1;
    if (playing) {
      music.play();
    } else {
      music.pause();
    }

  }, [playing])

  const sections = 
      typeof document !== `undefined`
        ? document.querySelectorAll('*[id^="section-"]')
        : [];


  const homeSection = 
    typeof document !== `undefined`
      ? document.getElementById('home')
      : undefined;
      
  React.useEffect(() => {
    const observers = []

    //home section
    let homeObserver;
    if (homeSection) {
      homeObserver = new IntersectionObserver(entries => {
        if(entries[0].isIntersecting === true) {
          setActiveSection(undefined)
        }
      }, { threshold: [0.5] })
  
      homeObserver.observe(homeSection)
    }

    //section observers
    sections.forEach((_section, i) => {
      observers.push(
        new IntersectionObserver(entries => {
          if(entries[0].isIntersecting === true) {
            setActiveSection(i)
          }
        }, { threshold: [0.5] }) // 1 doesn't seem to work, not sure why
      );
    })
    
    observers.forEach((observer, i) => {
      observer.observe(sections[i])
    })

    return () => {
      observers.forEach((observer, i) => observer.unobserve(sections[i]))
      if (homeObserver) {
        homeObserver.unobserve(homeSection)
      }
    }
  })

  const handleClick = (index:number) => {
    if (isSmall) {
      setMobileMenuActive(false);
    }
    scrollTo(`section-${index}`)
  }
  return (
    <StyledHeader style={{
      transform: `${mobileMenuActive ? 'translateX(100%)' : ''}`
    }}>
      {isSmall &&
        <button onClick={() => setMobileMenuActive(!mobileMenuActive)} className={`hamburger hamburger--squeeze ${mobileMenuActive ? 'is-active' : ''}`} type="button">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>  
      }
      <HeaderSections>
        <Section>
          <a href="https://www.instagram.com/elluchadortequila/" target="_blank">
            <CircleButton src={instagram} />
          </a>
          <a href="https://www.facebook.com/elluchadortequila/" target="_blank">
            <CircleButton src={facebook} />
          </a>
          <a href="https://twitter.com/LuchadorTequila" target="_blank">
            <CircleButton src={twitter} />
          </a>
          <a href="http://elluchadortequila.blogspot.com/" target="_blank">
            <CircleButton src={blog} />
          </a>
        </Section>
        <Section>
          {[...sections].map((_section, i) => {
            return (
              <SectionMask className={activeSection === i ? 'active' : 'inactive'} onClick={() => handleClick(i + 1)}>{sectionChars[i % sectionChars.length]}</SectionMask>
            )
          })}
        </Section>
        <Section>
          <div onClick={() => setInfoOpen(true)}>
            <CircleButton src={info} />
          </div>

          <div onClick={() => toggleMusic()}>
            <MusicButton src={playing ? pause : play} />
            <audio ref={audioRef} loop>
              <source src={luchadorMp3} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio> 
          </div>
        </Section>

      </HeaderSections>

      <Modal isOpen={infoOpen} onClose={() => setInfoOpen(false)}>
        <div>
          <StyledModalSection backgroundImage={background.childImageSharp.fluid.src}>
            <ModalSection className="left">
              <ModalHeader>
                <img style={{width: '320px', marginBottom: 0}} src={logoLuchadorLockup} />
              </ModalHeader>
              <BoxesImage>
                <LuchadorImage image={boxes} />
              </BoxesImage>
            </ModalSection>
            <ModalSection className="right">
              <BoxImages>
                <BoxStackImage>
                  <LuchadorImage image={boxStack} />
                </BoxStackImage>
                <BoxImage>
                  <LuchadorImage image={box} />
                </BoxImage>
              </BoxImages>
            </ModalSection>
            <ModalSection style={{flex: '0 0 100%', paddingTop: 0}}>
              <MailLink href="mailto:info@123spirits.com">
                <StyledP color={'black'}>
                  info@123spirits.com
                </StyledP>
              </MailLink>
            </ModalSection>
          </StyledModalSection>
        </div>
      </Modal>
    </StyledHeader>
  )
}


export default Header
